@import url(../../App.css);

/* .a-d-p-container {} */

.a-d-p-banner-contianer {
  background-color: #f5f5f5;
  display: flex;
}
.small-text6{
  padding: 2em 2em;
      font-size: 20px !important;
        color: var(--pureBlack);
        font-family: var(--MetropolisMedium);
        line-height: 20.8px;
}
.a-d-p-banner-left {
  padding: 3em 4em;
  width: 50%;
  margin: auto;
}

.a-d-p-banner-right {
  width: 100%;
  height: 580px;
  overflow: hidden;
  padding: 2em 1em ;
  height: 77vh;
  margin-top: 2em;
}

.a-d-p-image {
  /* height: 100%;
    width: 100%; */
  object-fit: cover;
  object-position: center;
  width: 100%;
  min-height: 100%;
  border-radius: 15px;
}

.a-d-p-heading {
  max-width: 402px;
}

.a-d-p-button {
  padding: 10px;
  font-size: 12px;
  margin-top: 1em;
}

.a-d-p-social-container {
  display: flex;
  margin-top: 5em;
  column-gap: 20px;
}

.a-d-p-social-icon {
  width: 25px;
  cursor: pointer;
}

.a-d-p-details-container {
  max-width: 80%;
  margin: auto;
  padding: 5em 0;
}

.a-d-p-green-block {
  margin-top: 3em;
  background-color: var(--lightGreen);
  width: 50px;
  height: 20px;
}

.a-d-p-details-heading {
  color: var(--themeDarkBlueTextColor);
  font-size: 20px;
  font-weight: 700;
}

.a-d-p-cards-contianer {
  margin-top: 3em;
  margin-bottom: 4em;
}

.a-d-p-cards-contianer-heading {
  display: flex;
  justify-content: space-between;
}

.a-d-mobile {
  display: none;
}

/* Mobile view */
@media only screen and (max-width: 992px) {
  .a-d-mobile {
    display: unset;
  }
  .a-d-desktop {
    display: none;
  }
  .a-d-p-banner-contianer {
    flex-direction: column-reverse;
  }
  .a-d-p-banner-right {
    width: unset;
  }
  .a-d-p-banner-left {
    width: unset;
    padding: 3em 0px;
    margin: 0 2em;
  }
  .a-d-head {
    font-size: 23px !important;
    /* padding-top: 2em !important; */
    line-height: 35px !important;
  }
  .a-d-p-social-container {
    margin-top: 3em;
  }
  .a-d-desc {
    padding-top: 3em;
  }
  .a-d-p-details-container {
    padding: 5em 40px !important;
  }
        .a-d-p-details-container1 {
          padding: 1em 40px !important;
          max-width: 100%;
        }
        .ap-ar-cards-container{
          /* display: none; */
        }
  .a-d-p-details-heading {
    padding-top: 14px;
  }
  .a-d-p-cards-contianer {
    padding: 0 40px !important;
  }
}
