@import url(../../App.css);
.slider-content {
  border-radius: 10px;
}
.newJobForm{
  box-sizing: border-box;
  
    /* Auto layout */
  
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    gap: 20px;
  
    max-width: 400px;
  
    border: 1px solid #A891F5;
    border-radius: 6px;
}
.newJobForm_h1{
  width: 360px;
    height: 56px;
  
    font-family: var(--MetropolisMedium);
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    /* or 117% */
  
  
    color: #403663;
}
.newJobForm_button{
  display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* padding: 16px 32px; */
    /* min-width:200px; */
    width: 360px;
    height: 48px;
    font-family: var(--MetropolisMedium);
  
    background: #221D35;
    border-radius: 100px;
  
    /* Inside auto layout */
  
 
}
.newJobForm_p{
    height: 16px;
  
  font-family: var(--MetropolisMedium);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    /* identical to box height */
  
  
    color: #A891F5;
}
.newJobForm_span{
  width: 143px;
    height: 16px;
  
    font-family: var(--MetropolisMedium);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    /* identical to box height */
  
  
    color: #525252;
}
.career_background_img {
  width: 93%;
  height: auto;
  margin: 0px auto 96px auto;
}

.career_background_img {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 20px;
  position: relative;
  height: 77vh;
  object-fit: cover;
  width: 100%;
}

.career_table_title {
  width: 50%;
  margin: 0px auto 160px auto;
}

.career_table_title h2 {
  font-size: 40px;
  color: #403663;
  font-weight: 500;
font-family: var(--MetropolisMedium);
  text-align: left;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
font-family: var(--MetropolisMedium);
  margin-bottom: 96px;
}

th {
  color: #a891f5;
  font-weight: 500;
  font-size: 16px;
  text-align: left;
  padding: 20px;
  text-transform: uppercase;
}

td {
  text-align: left;
  padding: 20px;
  color: #525252;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}
.view_all {
  width: 230px;
  height: 48px;
  background-color: #221d35;
  border-radius: 50px;
  color: #fff;
font-family: var(--MetropolisMedium);
  font-size: 16px;
}
.table_align {
  width: 100%;
  text-align: center;
}
.career_banner_text {
  position: absolute;
  bottom: -10%;
  left: 40%;
  text-align: center;
}
.career_banner_text h2 {
font-family: var(--CanelaLight);
  font-size: 40px;
  font-weight: 300;
  text-align: center;
  color: #fff;
}
.career_banner_text p {
  color: #fff;
  font-family: var(--MetropolisMedium);
  font-size: 16px;
  font-weight: 500;
}

.career-banner-mobile {
  display: none;
}

.table-mobile {
  display: none;
}
.apply-desktop{
  display: none;
}

.career-conta {
  padding: 1em 3em;
}
 .slick-next:before {
   color: white;
   padding-left: unset;
 }
/* Mobile view */
@media only screen and (max-width: 992px) {
  .apply-deskstop{
    display: none;
  }
  .apply-job{
    width: 100% !important;
    height: 100% !important;
  }
  .slick-next:before {
      color: white;
      padding-left: 20px;
    }
  .carrerslider {
    padding:unset !important;
  }
.apply-desktop {
  display: unset;
}
.apply-mobile{
      align-items: center;
        justify-content: center;
}
  .ssp-banner-bg-container2-career {
      background-image: url("https://d2s80gmgq3vhu4.cloudfront.net/HomeSlideImages/assets/Services_mobile/careers.jpg");
      height: 77vh;
      border-radius: 15px;
      position: relative;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      object-fit: cover;
    }
  .career-conta {
    padding: 1em 2em;
  }
  .career_background_img {
    width: unset;
    height: unset;
  }
  .career-banner-desktop {
    display: none;
  }
  .career-banner-mobile {
    display: unset;
  }
  .career_banner_text {
    position: unset;
  }
  .career_banner_text h2 {
    color: #403663;
    padding-top: 1em;
  }
  .career_banner_text p {
    color: #403663;
    padding-top: 1em;
    font-size: 14px;
  }
  
  .table_align {
    display: none;
  }
  .career_table_title {
    width: unset;
  }
  .table-mobile {
    display: unset;
  }
  .table-mobile-data-item {
    display: flex;
    justify-content: space-between;
  }
  .table-mobile-data {
    background-color: #e8e8e8;
    padding: 1em;
  }
  .table-mobile-data2 {
    background-color: #f5f5f5;
    padding: 1em;
  }
  .table-head {
    color: #a891f5;
    font-size: 14px;
    margin-bottom: 4px;
  }
  .table-subhead {
    color: #525252 !important;
    font-size: 24px !important;
    margin-bottom: 1em;
  }
  .table-title {
    color: #525252;
    font-size: 14px;
  }
  .mobile-viewall {
    padding: 6em 1em 4em;
  }
  .view_all {
    width: 292px;
  }
  .ls-m-l-c {
    display: none;
    width: unset;
  }
  .ls-m-r-c {
    position: unset;
    width: 100%;
    overflow-y: scroll;
  }
  .second-block-first-line {
    width: unset !important;
  }
  .second-block-content {
    width: unset !important;
  }
  .title-two {
    width: unset !important;
  }
  .footer-block {
    margin-top: 3em;
  }
  .modal-content {
    /* border-bottom-left-radius: 0px !important; */
    width: 100%;
  }
  .footer2 {
    padding: 4% 0% 8% 8% !important;
  }
  .mobile-footer {
    width: 150px !important;
    height: 40px !important;
  }
}

.carrerslider {
  padding: 2em 3em;
}
 @media only screen and (min-width: 1200px) and (max-width:1350px) {
.newJobForm_button {
  width: 230px;
  }
} 