  .maindivproperties {
  width: 100%;
  margin: auto;
  /* display: flex; */
  justify-content: space-between;
  /* position: relative; */
  background-image: url("https://d2s80gmgq3vhu4.cloudfront.net/HomeSlideImages/assets//Images/noiseBG.png");
  background-repeat: repeat;

  padding: 6em 3em;
  background-color: var(--white);
  z-index: 1000;
  border-top: 5px solid #f5f5f5;
}
.mandated-section-one-image {
  background: url("https://d2s80gmgq3vhu4.cloudfront.net/HomeSlideImages/assets/Images/Mandatedtopimage.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 20px;
  position: relative;
  height: 77vh;
  object-fit: cover;
}

.mandated-section-two {
  margin: 3% 14% 0% 14%;
  /* background-color: red; */
}

.mandated-section-two-sub-section-one-text {
  width: 251px;
  height: 29px;

font-family: var(--MetropolisMedium);
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 120%;

  color: #3d3d3d;
  margin-top: 2%;
}

.mandated-section-two-sub-section-two {
  margin-bottom: 5%;
}

.mandated-section-two-sub-section-tree {
  margin-bottom: 10%;
}

.one {
  background: #f5f5f5 !important;
  border: 1px solid #e0e0e0 !important;
  border-radius: 6px !important;
  /* -16px; */
  margin-top: -9px;
}

.dropdown-toggle::after {
  /* display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; */
  margin-left: 50px !important;
}

.button-div {
  /* padding: 12px 40px; */
  /* width: 200px; */
  /* height: 40px; */

  /* background: #ebebeb; */
  border-radius: 100px;
  margin-top: 1.5%;
font-family: var(--MetropolisMedium);
  font-style: normal;
  line-height: 16px;
  text-align: center;
  color: #221d35;
  margin-top: 12.5%;
}

.dropdown-one {
font-family: var(--MetropolisMedium);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  color: #3d3d3d;
}

.mandated-image {
  display: none;
}
 .mandated-color {
   text-color: #e0e0e0;
 }


/* Mobile view */
@media only screen and (max-width: 550px) {
 
  .left-line1{
    display: none;
  }
  .mandated-section-one-image {
    display: none;
    
  }
  .mandated-image {
        display: unset;
          width: 100%;
          height: 77vh;
        border-radius: 15px;
  }
  .mandated-top-sec {
    margin: 0 10% 0% 10% !important;
  }

  .filter-content {
    display: flex;
    justify-content: space-between;
  }
  
}
.mandekstop {
  display: none;
}
.medium-heading101{
      /* font-size: var(--medSize); */
        font-size: 27px;
        font-family: var(--CanelaLight);
        color: var(--white) !important;
        margin: unset;
        font-weight: 500;
        line-height: 57.6px;
}
@media only screen and (max-width: 992px){
  .mandekstop{
    display: unset;
  }
  .medium-heading{
    color: #221d35 I !important;
  }
}
